import React from 'react'
import propTypes from 'prop-types'
import './styles.scss'
import { CheckCircle } from '@mui/icons-material'

function Confirmation(props) {
  return (
    <div styleName="confirmation">
      <div styleName="icon">
        <CheckCircle />
      </div>
      <div styleName="content">{props.children}</div>
    </div>
  )
}

Confirmation.propTypes = {
  children: propTypes.node
}

export default Confirmation
