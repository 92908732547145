import React, { useState } from 'react'
import { useDispatch /*, useSelector*/ } from 'react-redux'
import { useHistory } from 'react-router'
import { updateFollowUpAnswers } from '../../../../../actions/quiz.actions'
import QuizCard from '../quiz-card'
import { SELECT_MULTIPLE_CHECK_BOX_WITH_SIDEBAR_STYLE } from '../../../../quiz/inputs/select-multiple'

export const question = {
  _id: '5f04ba3f7efb80a4de9806e4',
  items: [
    {
      text: 'Sensitivity',
      value: 8
    },
    {
      text: 'Redness',
      value: 6
    },
    {
      text: 'Fine Lines or Wrinkles',
      value: 4
    },
    {
      text: 'Loss of Firmness or Elasticity',
      short_text: 'Firmness/elasticity',
      value: 3
    },
    {
      text: 'Hyperpigmentation',
      short_text: 'Hyperpigmentation',
      value: 5
    },
    {
      text: 'Acne',
      value: 2
    },
    {
      text: 'Dryness',
      value: 7
    }
  ],
  type: 'SelectMultiple',
  heading: 'Has anything changed to your major skin concerns?',
  createdAt: '2020-11-05T18:24:51.075+0000',
  updatedAt: '2021-02-02T16:48:42.845+0000',
  __v: 0
}

function UpdateProfileCard(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [displayContent, setDisplayContent] = useState(false)
  const [accepting, setAccepting] = useState(false)

  const onAccept = async answers => {
    setAccepting(true)
    dispatch(updateFollowUpAnswers(answers))
    setAccepting(false)
    history.push('/account/skin/update/profile')
  }

  const onCancel = e => {
    e?.preventDefault()
    setDisplayContent(false)
  }

  const onClickModal = e => {
    e?.preventDefault()
    setDisplayContent(true)
  }

  return (
    <QuizCard
      icon="update"
      title="Update My Profile"
      subtitle="Tell us about your skin or life changes."
      whiteBackground
      ctaText={!displayContent ? 'Update' : null}
      onClick={onClickModal}
      ctaHandler={onClickModal}
      question={displayContent ? question : undefined}
      questionHeadingBold
      onAccept={onAccept}
      onCancel={onCancel}
      accepting={accepting}
      ctaArrow={!displayContent}
      acceptText="Continue"
      alwaysDisplayActionBar
      answersTheme={SELECT_MULTIPLE_CHECK_BOX_WITH_SIDEBAR_STYLE}
      {...props}
    />
  )
}

export default UpdateProfileCard
