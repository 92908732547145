import React from 'react'
import Confirmation from './confirmation'
import ErrorMessage from './error-message'
import propTypes from 'prop-types'
import { Typography, Container, Button, Box, Stack, useMediaQuery } from '@mui/material'
import ProvenButton from '../../../../shared/proven-button'
import ProvenIcon from '../../../../shared/proven-icon'
import { createProvenTheme } from '../../../../../styles/theme-proven'

const theme = createProvenTheme()

function MenuCard(props) {
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'))
  const handleCta = () => {
    if (props.ctaHandler) {
      props.ctaHandler()
    }
  }
  const handleClick = () => {
    if (props.onClick) {
      props.onClick()
    }
  }
  return (
    <Box
      sx={{
        ml: 1,
        '&:last-child': {
          mb: isMdUp && props.whiteBackground ? 0 : '60px'
        }
      }}
      onClick={handleClick}
    >
      <Stack
        sx={{
          pb: props.whiteBackground ? 2 : 4,
          pt: props.whiteBackground ? 3 : 4,
          px: props.whiteBackground ? 3 : 4,
          flexDirection: 'row',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          backgroundColor: props.whiteBackground || props.selected ? 'gray.white' : 'transparent',
          boxShadow: props.whiteBackground || props.selected ? '3' : 'none',
          borderRadius: props.whiteBackground || props.selected ? 2 : '0px',
          cursor: 'pointer'
        }}
      >
        {props.image && (
          <Stack
            sx={{
              padding: 3,
              alignItems: 'center',
              backgroundColor: props.imageBackgroundColor,
              justifyItems: 'center'
            }}
          >
            <img src={props.image} alt={props.title} />
          </Stack>
        )}

        <Stack sx={{ width: 'auto', alignItems: 'flex-start' }}>
          {(() => {
            switch (props.icon) {
              case 'account':
                return (
                  <ProvenIcon name="account" type="tab" sx={{ width: '30px', height: '30px' }} />
                )
              case 'credit':
                return (
                  <ProvenIcon
                    name="credits-referrals"
                    type="dashboard"
                    sx={{ width: '30px', height: '30px' }}
                  />
                )
              case 'order':
                return (
                  <ProvenIcon
                    name="order-history"
                    type="dashboard"
                    sx={{ width: '30px', height: '30px' }}
                  />
                )
              case 'update':
                return (
                  <ProvenIcon
                    name="update-my-profile"
                    type="kit"
                    sx={{
                      transform: isMdUp ? 'scale(2)' : 'scale(1.2)',
                      mt: isMdUp ? 3 : 1,
                      mx: isMdUp ? 3 : 0
                    }}
                  />
                )

              case 'skincare':
                return (
                  <ProvenIcon
                    name="your-preferences"
                    type="kit"
                    sx={{
                      transform: isMdUp ? 'scale(2)' : 'scale(1.2)',
                      mt: isMdUp ? 3 : 1,
                      mx: isMdUp ? 3 : 0
                    }}
                  />
                )

              case 'feedback':
                return (
                  <ProvenIcon
                    name="customer-care"
                    type="kit"
                    sx={{
                      transform: isMdUp ? 'scale(2)' : 'scale(1.2)',
                      mt: isMdUp ? 3 : 1,
                      mx: isMdUp ? 3 : 0
                    }}
                  />
                )

              default:
                return (
                  props.icon &&
                  !props.noIcon && (
                    <img
                      src={props.icon}
                      alt={props.icon}
                      sx={{
                        minWidth: '60px',
                        height: 'auto'
                      }}
                    />
                  )
                )
            }
          })()}
        </Stack>
        <Stack
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            gap: 1,
            ml: 3
          }}
        >
          {props.label && (
            <Typography
              sx={{
                display: 'block',
                textTransform: 'uppercase',
                marginBottom: '10px',
                letterSpacing: '0px'
              }}
            >
              {props.label}
            </Typography>
          )}
          {props.title && (
            <Typography sx={{ alignSelf: 'stretch' }} variant="title2" color="gray.elysian">
              {props.title}
            </Typography>
          )}
          {props.subtitle && (
            <Typography sx={{ alignSelf: 'stretch' }} variant="footnote" color="gray.darkGray">
              {props.subtitle}
            </Typography>
          )}

          {props.ctaText && (
            <Button
              variant="text"
              color="primary"
              sx={{
                p: 0,
                m: 0,
                height: '33px !important'
              }}
              aria-label={props.ctaText}
              style={props.ctaStyle}
              onClick={handleCta}
            >
              {props.ctaText}
              {props.ctaArrow && !props.ctaArrowFloating && (
                <img
                  src="https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-system-arrow-right.svg"
                  alt="Arrow Right"
                  sx={{ width: '70%', pl: 2 }}
                />
              )}
            </Button>
          )}
          {props.buttonText && (
            <ProvenButton
              sm
              blackStroke
              textTransformNone
              width="100%"
              text={props.buttonText}
              onClick={props.buttonHandler}
            />
          )}
          {((!props.ctaText && props.ctaArrow) || props.ctaArrowFloating) && (
            <img
              src="https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-system-arrow-right.svg"
              alt="Arrow Right"
              sx={{ width: '70%', marginLeft: 1 }}
            />
          )}
        </Stack>
      </Stack>

      <Box>{props.children}</Box>
      {props.actionBar && (
        <Stack
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            borderTop: '1px solid #D9D9D9',
            paddingTop: '15px',
            marginTop: '15px'
          }}
        >
          <ProvenButton
            sm
            link
            textTransformNone
            text={props.cancelText}
            onClick={props.handleCancel}
            disabled={props.accepting}
          />
          <ProvenButton
            sm
            black
            textTransformNone
            text={props.acceptText}
            onClick={props.handleAccept}
            loading={props.accepting}
          />
        </Stack>
      )}
    </Box>
  )
}
MenuCard.defaultProps = {
  background: 'transparent',
  cancelText: 'Cancel',
  labelStyle: {},
  ctaStyle: {}
}
MenuCard.propTypes = {
  paddingMd: propTypes.bool,
  icon: propTypes.string,
  label: propTypes.string,
  labelStyle: propTypes.object,
  title: propTypes.string,
  titleMd: propTypes.bool,
  subtitle: propTypes.string,
  ctaHandler: propTypes.func,
  ctaText: propTypes.string,
  ctaStyle: propTypes.object,
  ctaArrow: propTypes.bool,
  ctaArrowFloating: propTypes.bool,
  noIcon: propTypes.bool,
  transparent: propTypes.bool,
  selected: propTypes.bool,
  whiteBackground: propTypes.bool,
  background: propTypes.string,
  children: propTypes.node,
  actionBar: propTypes.bool,
  cancelText: propTypes.string,
  handleCancel: propTypes.func,
  acceptText: propTypes.string,
  handleAccept: propTypes.func,
  imageMd: propTypes.bool,
  image: propTypes.string,
  imageBackgroundColor: propTypes.string,
  buttonText: propTypes.string,
  buttonHandler: propTypes.func,
  onClick: propTypes.func,
  accepting: propTypes.bool
}
MenuCard.Confirmation = Confirmation
MenuCard.ErrorMessage = ErrorMessage
export default MenuCard
