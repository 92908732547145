import React from 'react'
import propTypes from 'prop-types'
import ErrorIcon from '@mui/icons-material/Error'
import './styles.scss'

function ErrorMessage(props) {
  return (
    <div styleName="error-message">
      <div styleName="icon">
        <ErrorIcon />
      </div>
      <div styleName="content">{props.children}</div>
    </div>
  )
}

ErrorMessage.propTypes = {
  children: propTypes.node
}

export default ErrorMessage
