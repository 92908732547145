import React, { useEffect, useRef, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import Typewriter from 'typewriter-effect'
import { MobileIngredient } from '../ingredient'
import BeforeAfterCarouselImage from '../../../../../../shared/before-after-carousel/image'
import ProvenShape from '../../../../../../shared/proven-shape'
import { onDesktop } from '../../../../../../../styles/theme-proven/responsive'
import AnimatedNumber from '../../../../../../shared/AnimatedNumber'
import useIsInViewport from 'hooks/useIsInViewport'

const gridSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer'
}

function AccordionItem({
  title,
  description,
  percentage,
  percentageDescription,
  open,
  handleSelectConcern,
  handleCloseAccordion,
  ingredients,
  afterImage,
  beforeImage,
  sourceLabel
}) {
  const isSerumProductPage = useRouteMatch(['/account/shop/serum', '/personalized-serum'])
  const isCongratulationsSerumPage = useRouteMatch('/account/congratulations/serum')
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'))
  const percentRef = useRef()
  const titleRef = useRef()
  const isTitleInViewport = useIsInViewport(titleRef)
  const [showTitle, setShowTitle] = useState(false)

  console.log('showTitle', showTitle)

  useEffect(() => {
    if (isTitleInViewport) {
      setShowTitle(true)
    }
  }, [isTitleInViewport])

  const showEfficacySlides =
    !isCongratulationsSerumPage && !isSerumProductPage && beforeImage && afterImage
  const showPercentage = !isCongratulationsSerumPage && !isSerumProductPage && !!percentage

  return (
    <Box sx={{ mb: 3 }}>
      <Box
        sx={{ ...gridSx, mb: open ? 1 : 0 }}
        onClick={!open ? handleSelectConcern : isMdUp ? handleSelectConcern : handleCloseAccordion}
        ref={titleRef}
      >
        <Typography zIndex={4} pt={1} variant="h3" color="primary.main">
          <Typewriter
            options={{
              strings: [title],
              autoStart: showTitle,
              deleteSpeed: Infinity
            }}
          />
        </Typography>
        <IconButton>
          {open ? <KeyboardArrowUpIcon color="primary" /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Box>

      {open && (
        <>
          {isMdUp && (
            <Typography
              variant="title1"
              color="gray.elysian"
              component="div"
              sx={{ textTransform: 'uppercase', mb: 1, mt: 4 }}
            >
              Personalized Ingredients
            </Typography>
          )}
          <Typography variant="body2" color="gray.darkGray" sx={{ mb: 3, [onDesktop]: { mb: 5 } }}>
            {description}
          </Typography>

          {showEfficacySlides && (
            <Box sx={{ zIndex: 4, pb: 2 }}>
              {isMdUp && (
                <Typography
                  variant="title1"
                  color="gray.elysian"
                  component="div"
                  sx={{ textTransform: 'uppercase', mb: 2 }}
                >
                  Clinically Proven efficacy
                </Typography>
              )}
              <Box
                sx={{
                  margin: 0,
                  outline: 'none',
                  maxHeight: { xs: '200px', md: '260px' },
                  display: 'flex',
                  '& figure': {
                    maxWidth: '461px',
                    width: ['100%', '100%', '100%', '461px']
                  }
                }}
                className="slick-center"
              >
                <BeforeAfterCarouselImage
                  after={afterImage}
                  before={beforeImage}
                  position={40}
                  singleSlide
                  withoutLazy
                />
              </Box>
              <Typography variant="footnoteLight" color="gray.darkGray">
                {sourceLabel}
              </Typography>
            </Box>
          )}

          {showPercentage && (
            <Box gridArea="firstPercentage" sx={{ pb: 5 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ProvenShape
                  zIndex={1}
                  baseWidth={isMdUp ? 3.6 : 3}
                  baseHeight={isMdUp ? 3.6 : 3}
                  viewBox="0 0 32 32"
                  name="circle"
                  backgroundColor="gray.lightBlue"
                  justifyContent="flex-start"
                >
                  <Typography
                    pr={2}
                    pl={2}
                    zIndex={4}
                    variant="h3"
                    color="primary.main"
                    display="flex"
                    alignItems="center"
                    ref={percentRef}
                  >
                    <AnimatedNumber value={parseInt(percentage)} parentRef={percentRef} />%
                  </Typography>
                </ProvenShape>
                {percentageDescription && (
                  <Typography alignSelf="start" zIndex={4} variant="title2" color="primary.main">
                    {percentageDescription}
                  </Typography>
                )}
              </Box>
            </Box>
          )}

          {!isMdUp && (
            <Box zIndex={4} sx={{ gridArea: 'description', pb: 3, pt: 1 }}>
              <Typography
                variant="title1"
                color="gray.elysian"
                component="div"
                sx={{ textTransform: 'uppercase' }}
              >
                Personalized Ingredients
              </Typography>
            </Box>
          )}

          {!isMdUp && (
            <Box
              gridArea="ingredients"
              sx={{
                height: '176px',
                pl: 2.5,
                pt: 3.75,
                maxWidth: 'calc(100vw - 32px)',
                overflowX: 'auto',
                '&::-webkit-scrollbar': {
                  '-webkit-appearance': 'none'
                },
                '&::-webkit-scrollbar-track': {
                  'background-color': 'transparent'
                },
                '&::-webkit-scrollbar-thumb': {
                  'background-color': 'transparent'
                }
              }}
            >
              <Stack flexDirection="row" gap={4}>
                {(ingredients || [])
                  .slice(0, 3)
                  .map(
                    i =>
                      i && (
                        <MobileIngredient
                          key={`ingredient-${i.ingredient_name}`}
                          ingredientData={i}
                        />
                      )
                  )}
              </Stack>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export default AccordionItem
