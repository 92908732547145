import React, { useState } from 'react'
import propTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from '@mui/material'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { question } from '../../dashboard/skin/update-profile-card'
import { updateFollowUpAnswers } from '../../../../actions/quiz.actions'
import SelectMultiple, {
  SELECT_MULTIPLE_LIGHT_GREEN_STYLE
} from '../../../quiz/inputs/select-multiple'
import { onDesktop } from '../../../../styles/theme-proven/responsive'

function UpdateConcernsFirstQuestion({
  questionTitle = 'What are your main skin concerns?',
  questionTitleVariant = 'h3',
  questionTitle2,
  answersSelected,
  setAnswersSelected,
  showButtons = true,
  nextStep,
  multipleSelectorStyle = SELECT_MULTIPLE_LIGHT_GREEN_STYLE
}) {
  const dispatch = useDispatch()
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'))

  const [processing, setProcessing] = useState(false)

  const onContinue = () => {
    setProcessing(true)
    dispatch(updateFollowUpAnswers(answersSelected))
    setProcessing(false)
    nextStep()
  }

  return (
    <Box>
      <Typography
        variant={questionTitleVariant}
        sx={{
          my: questionTitleVariant === 'title2' ? 4 : 2
        }}
        component="h3"
      >
        {questionTitle}
      </Typography>
      {questionTitle2 && (
        <Typography variant="title2" sx={{ mb: 5 }} component="div">
          {questionTitle2}
        </Typography>
      )}
      {isMdUp ? (
        <Box sx={{ mb: 5 }}>
          <SelectMultiple
            _id={question._id}
            items={question.items}
            setAnswer={setAnswersSelected}
            answer={answersSelected[question._id]}
            multipleSelectorStyle={multipleSelectorStyle}
            questionStyle={question.style}
          />
        </Box>
      ) : (
        <Box sx={{ mb: 2.5 }}>
          <SelectMultiple
            _id={question._id}
            items={question.items}
            setAnswer={setAnswersSelected}
            answer={answersSelected[question._id]}
            multipleSelectorStyle={multipleSelectorStyle}
            questionStyle={question.style}
          />
        </Box>
      )}
      {showButtons && (
        <Box sx={{ width: '100%', [onDesktop]: { width: '267px' } }}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={onContinue}
            disabled={processing}
          >
            CONTINUE
          </Button>
        </Box>
      )}
    </Box>
  )
}

UpdateConcernsFirstQuestion.propTypes = {
  nextStep: propTypes.func
}

export default UpdateConcernsFirstQuestion
