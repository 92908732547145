/* eslint no-console: "off" */
import {
  GET_QUESTIONS,
  SET_ANSWER,
  SET_ANSWERS,
  SET_TRANSITION,
  POST_QUIZ_DATA_ERROR,
  POST_QUIZ_DATA_SUCCESS,
  SET_VALIDATION_ERROR,
  SET_USER_QUIZ_DATA,
  CREATE_USER_QUIZ_DATA,
  SUCCESS_USER_QUIZ_DATA,
  FAIL_USER_QUIZ_DATA,
  SET_REFERRER,
  SET_QUIZ_PENDING_STATUS,
  SET_USER_PRESALE_DATA,
  CREATE_USER_PRESALE_DATA,
  SUCCESS_USER_PRESALE_DATA,
  FAIL_USER_PRESALE_DATA,
  CLEAR_QUIZ_ANSWERS,
  COMPLETED_QUIZ,
  SET_VERSION,
  GET_INGREDIENTS,
  GET_CLINICAL_RESULTS,
  SET_AUTOCOMPLETE_INPUT_VALUE,
  SIGNIN_PASSWORD_FAIL,
  SET_FOLLOWUP_QUESTIONS,
  CREATE_NOTIFICATION,
  SET_FOLLOWUP_ANSWERS,
  SET_TRANSITION_QUESTION,
  SET_QUIZ_SUMMARY_REDIRECT_URL
} from '../constants/actionTypes'
import { history } from '../store/configureStore'
import { pathOr, pathEq, findIndex } from 'ramda'
import {
  filterQuestionsSelector,
  currentAnswerSelectorById,
  answersSelectorGeneric,
  questionsSelectorGeneric,
  questionsBSelectorGeneric,
  userInfoSelector,
  accountSelector
} from '../utils/selectors'
import {
  apiUrl,
  campaignCaptureQuizUrl,
  userUrl,
  searchProductsUrl,
  userQuizUrl,
  presaleUserUrl
} from '../constants/endpoints'
import { checkValidUser } from './auth0.actions'
import provenApi from '../services/proven-api'
import { addValuesToObjectOnlyIfDefined, validateField } from '../utils/helpers'
import { countries } from '@provenai/shared'
import { QUESTIONNAIRE_ID_SKIN_PASSWORDLESS } from '../constants/configs/questionnaire'
import {
  setAccountCompleteQuiz,
  setAccountPersonalInfo,
  setMostRecentQuizId
} from './account.actions'
import {
  getAnswers,
  getCongratPageUri,
  getNameFromAnswers,
  getQuizTypeByQuestionnaireId,
  getQuizUriPrefix,
  getQuizEndingPageUri,
  getZipCodeFromAnswers,
  getTransitionForQuestion,
  hasTransitionForQuestion,
  transitionIsInSomeNextQuestion
} from '../utils/quiz/quiz.helpers'
import { getOnAuthTargetRouteAfterQuiz } from './auth.actions'
import { isAuthenticated as checkIsAuthenticated } from '../utils/auth/auth'

const getQuestionsAction = (
  questions,
  variant,
  questionnaireId = QUESTIONNAIRE_ID_SKIN_PASSWORDLESS
) => ({
  type: GET_QUESTIONS,
  questions,
  variant,
  questionnaireId
})

export const captureQuizUrl = `${apiUrl}capture-quiz`
export const getQuestionsUrl = `${apiUrl}questions`
export const getIngredientsUrl = `${apiUrl}quiz/ingredients`
export const getValidateEmailUrl = `${apiUrl}support/email-validate`

export const getIngredients = questionnaireId => (dispatch, getState) => {
  // eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    try {
      const state = getState()
      const pathAnswersSelector = answersSelectorGeneric(questionnaireId)
      const answers = pathAnswersSelector(state)

      const { data } = await provenApi.post(getIngredientsUrl, { answers })
      dispatch(getIngredientsAction(data, questionnaireId))
      resolve(data)
    } catch (error) {
      reject(error)
    }
  })
}
const getIngredientsAction = (data, questionnaireId = QUESTIONNAIRE_ID_SKIN_PASSWORDLESS) => ({
  type: GET_INGREDIENTS,
  data,
  questionnaireId
})

export const getClinicalResults = numResults => (dispatch /*getState*/) => {
  const num = numResults ? numResults : 1
  // eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await provenApi.get(`quiz/clinical-results?numResults=${num}`)
      dispatch({
        type: GET_CLINICAL_RESULTS,
        data
      })
      resolve(data)
    } catch (error) {
      reject(error)
    }
  })
}

// export const decrementIngredients =
//   (num = 1) =>
//   async dispatch => {
//     dispatch(decrementIngredientsAction(num))
//   }
// const decrementIngredientsAction = num => ({
//   type: DECREMENT_INGREDIENTS,
//   num
// })

const subscribeQuestion = {
  question: {
    group: 'Proven',
    heading: 'You’re one step away from good skin',
    subHeading: 'Fill in your details to complete your personalization',
    name: 'subscribe',
    type: 'Subscribe'
  }
}

export const populateSignedInValuesForAnswers =
  (questionnaireId = QUESTIONNAIRE_ID_SKIN_PASSWORDLESS) =>
  (dispatch, getState) => {
    const state = getState()
    // const pathA = questionnaireId ? `questions_${questionnaireId}` : 'questions'
    // const pathB = questionnaireId ? `questions_b_${questionnaireId}` : 'questions_b'
    // const questionsA = pathOr([], ['quiz', pathA], state)
    // const questionsB = pathOr([], ['quiz', pathB], state)

    const pathASelector = questionsSelectorGeneric(questionnaireId)
    const pathBSelector = questionsBSelectorGeneric(questionnaireId)
    const questionsA = pathASelector(state)
    const questionsB = pathBSelector(state)
    const user = pathOr({}, ['account', 'info'], state)

    if (user && user.email && user.email !== '') {
      dispatch(populateSignedInValuesForVariant(questionsA, user, questionnaireId))
      dispatch(populateSignedInValuesForVariant(questionsB, user, questionnaireId))
    }
  }

export const populateSignedInValuesForVariant = (questions, user, questionnaireId) => dispatch => {
  const name = questions.find(q => q.question.name === 'name')
  if (name) {
    dispatch(
      setAnswer(
        {
          [name.question._id]: `${user.firstName || ''} ${user.lastName || ''}`.trim()
        },
        questionnaireId
      )
    )
  }

  const zipCode = questions.find(q => q.question.name === 'zip')
  if (zipCode) {
    dispatch(setAnswer({ [zipCode.question._id]: user.zipCode }, questionnaireId))
  }

  const email = questions.find(q => q.question.name === 'signup')
  if (email) {
    dispatch(setAnswer({ [email.question._id]: user.email }, questionnaireId))
  }

  // TODO pre-populate country code when it's returned from BE
}

export const getQuestions = questionnaireId => async dispatch => {
  try {
    const questionUrl = questionnaireId
      ? `${getQuestionsUrl}?questionnaireId=${questionnaireId}`
      : getQuestionsUrl
    const { data } = await provenApi.get(questionUrl)

    //===== NOT SURE WE NEED THIS =====
    const questions = pathOr([], ['result', 'questionnaire', 'questions'], data).concat(
      subscribeQuestion
    )
    //=================================

    dispatch(getQuestionsAction(questions, 'A', questionnaireId))
    const version = pathOr(0, ['result', 'questionnaire', '_v'], data)
    const id = pathOr(0, ['result', 'questionnaire', '_id'], data)
    dispatch(setQuestionnaireVersion({ version: version, id: id, variant: 'A' }, questionnaireId))
  } catch (error) {
    console.log(error) // eslint-disable-line
  }
  try {
    const questionUrl = questionnaireId
      ? `${getQuestionsUrl}?questionnaireId=${questionnaireId}`
      : `${getQuestionsUrl}`
    const { data } = await provenApi.get(questionUrl)
    const questions = pathOr([], ['result', 'questionnaire', 'questions'], data).concat(
      subscribeQuestion
    )
    dispatch(getQuestionsAction(questions, 'B', questionnaireId))
    const version = pathOr(0, ['result', 'questionnaire', '_v'], data)
    const id = pathOr(0, ['result', 'questionnaire', '_id'], data)
    dispatch(setQuestionnaireVersion({ version: version, id: id, variant: 'B' }, questionnaireId))
  } catch (error) {
    console.log(error) // eslint-disable-line
  }
}

//This function was renamed from 'clearQuizAnswers'
export const clearQuizAnswersAndPopulateSignedInValuesForAnswers =
  (variant, questionnaireId) => dispatch => {
    dispatch(clearQuizAnswers(variant, questionnaireId))
    dispatch(populateSignedInValuesForAnswers(questionnaireId))
  }

/* This function is used to clean the quiz answers from redux after the user sees the Quiz summary page.
  Previously it was not necessary because the answers were removed when calling to the action SUCCESS_USER_QUIZ_DATA
  But now the removal operation is optional since when executed before showing the quiz summary page
  it is avoided to have the summary answers visible to the user
  */
export const clearQuizAnswers = (variant, questionnaireId) => {
  return { type: CLEAR_QUIZ_ANSWERS, variant, questionnaireId }
}

export const setQuestionnaireVersion = (
  version,
  questionnaireId = QUESTIONNAIRE_ID_SKIN_PASSWORDLESS
) => ({
  type: SET_VERSION,
  version,
  questionnaireId
})

const getSetAnswerAction = (answer, questionnaireId = QUESTIONNAIRE_ID_SKIN_PASSWORDLESS) => ({
  type: SET_ANSWER,
  answer,
  questionnaireId
})

export const setReferrer = referrer => dispatch => dispatch({ type: SET_REFERRER, referrer })

export const setAnswer = (answer, questionnaireId) => dispatch => {
  // console.log(`quiz.actions - setAnswer --> questionnaireId: `, questionnaireId, answer)
  dispatch(getSetAnswerAction(answer, questionnaireId))
}

export const completedQuiz =
  (questionnaireId = QUESTIONNAIRE_ID_SKIN_PASSWORDLESS) =>
  dispatch =>
    dispatch({
      type: COMPLETED_QUIZ,
      questionnaireId
    })

export const goToNextQuestion =
  (question, variant = 'A', questionnaireId, isQuizEndingPage = false) =>
  async (dispatch, getState) => {
    console.log('countries', countries)

    const filteredQuestions = filterQuestionsSelector(variant, questionnaireId)(getState())
    const currentQuestionIndex = findIndex(
      pathEq(['question', '_id'], question._id),
      filteredQuestions
    )
    const currentQuestion =
      filteredQuestions[currentQuestionIndex] && filteredQuestions[currentQuestionIndex].question
    const nextQuestion =
      filteredQuestions[currentQuestionIndex + 1] &&
      filteredQuestions[currentQuestionIndex + 1].question

    // TODO CHECK MERGE (OLD CODE)
    // const answer = currentAnswerSelectorById(question, questionnaireId)(getState())
    const answer = currentAnswerSelectorById({ ...question, questionnaireId })(getState())
    const countryCode = currentAnswerSelectorById({ _id: 'countryCode' })(getState())

    let invalid = false
    if (
      (question.validityCheck === undefined || question.validityCheck) &&
      question.name !== 'zip'
    ) {
      invalid = await validateField(question, answer, countryCode)
    }

    if (invalid) {
      dispatch(setValidationError(invalid, questionnaireId))
    } else if (nextQuestion) {
      if (currentQuestion.name === 'zip') {
        dispatch(completedQuiz(questionnaireId))
      } else if (currentQuestion.name === 'signup') {
        try {
          const { status } = await checkValidUser(answer, true)
          if (status === 'RESET_REQUIRED') {
            dispatch(passwordResetRequired(answer))
          }
          // eslint-disable-next-line
          } catch (_err) {}
      }

      const isAuthenticated = await checkIsAuthenticated()
      if (isAuthenticated && nextQuestion.name === 'signup') {
        const shouldRedirectToQuizEndingPage =
          !isQuizEndingPage && getQuizEndingPageUri(questionnaireId)
        const answers = getAnswers(questionnaireId, getState())
        // console.log(`quiz.actions - goToNextQuestion - answers: `, answers)
        await dispatch(patchUserQuiz(answers, questionnaireId, shouldRedirectToQuizEndingPage)) // it is going to redirect to quizEndingPage we don't clear answers yet, to avoid missing the quizEndingPage when user clicks back

        const waitListCountries = countries.all.filter(c => !c.provenActive)
        if (countryCode && waitListCountries.length) {
          if (waitListCountries.includes(countryCode)) {
            return history.push('/account/coming-soon')
          }
        }

        //if the current page is not the quiz ending page and if there is one ending page for the quiz then redirect to the ending page
        const redirectUri = shouldRedirectToQuizEndingPage
          ? getQuizEndingPageUri(questionnaireId)
          : getOnAuthTargetRouteAfterQuiz(getQuizTypeByQuestionnaireId(questionnaireId), getState())
        history.push({
          pathname: redirectUri,
          state: {
            from: 'quiz'
          }
        })
        return
      }

      dispatch({
        type: SET_TRANSITION_QUESTION,
        payload: currentQuestion
      })
      const quizUri = getQuizUriPrefix(questionnaireId)
      let nextQuizUrl = ''

      // Check if the current page has a transition associated (to be the next page) and the next questions does not have the transition page
      // (transitionIsInSomeNextQuestion condition is added since the transition page can be shown next to two different questions, depending on the user's answers, since the user's answers make the filteredQuestions be filtered in different way )
      if (
        !question.isTransition &&
        hasTransitionForQuestion(questionnaireId, currentQuestion?.name) &&
        !transitionIsInSomeNextQuestion(
          questionnaireId,
          filteredQuestions.slice(currentQuestionIndex + 1).map(q => q.question.name),
          getTransitionForQuestion(questionnaireId, currentQuestion?.name).transitionName
        )
      ) {
        const { transitionName } = getTransitionForQuestion(questionnaireId, currentQuestion?.name)
        nextQuizUrl = transitionName
          ? quizUri + transitionName
          : (nextQuizUrl = quizUri + pathOr('', ['name'], nextQuestion))
      } else {
        nextQuizUrl = quizUri + pathOr('', ['name'], nextQuestion)
      }

      dispatch(getSetTransitionAction(nextQuestion.transition))
      history.push(nextQuizUrl)
      dispatch(setValidationError(undefined, questionnaireId))
    }
  }

const getPostQuizDataSuccessAction = ({ result }) => ({
  type: POST_QUIZ_DATA_SUCCESS,
  result
})

const getPostQuizDataErrorAction = error => ({
  type: POST_QUIZ_DATA_ERROR,
  error
})

export const submitQuizResults = results => async dispatch => {
  history.push('/final')
  try {
    const { data } = await provenApi.post(captureQuizUrl, results)
    dispatch(getPostQuizDataSuccessAction(data))
  } catch (error) {
    const message = pathOr({}, ['response', 'data'], error)
    dispatch(getPostQuizDataErrorAction(JSON.stringify(message)))
  }
}

export const getSetTransitionAction = transition => ({
  type: SET_TRANSITION,
  transition
})

export const setTransition = transition => dispatch => {
  dispatch(getSetTransitionAction(transition))
}

const getSetValidationErrorAction = (
  error,
  questionnaireId = QUESTIONNAIRE_ID_SKIN_PASSWORDLESS
) => ({
  type: SET_VALIDATION_ERROR,
  error,
  questionnaireId
})

export const setValidationError = (invalid, questionnaireId) => dispatch => {
  dispatch(getSetValidationErrorAction(invalid, questionnaireId))
}

export const setAnswers = (answers, questionnaireId = QUESTIONNAIRE_ID_SKIN_PASSWORDLESS) => ({
  type: SET_ANSWERS,
  answers,
  questionnaireId
})

const setUserQuizInfo = data => ({
  type: SET_USER_QUIZ_DATA,
  data
})

const setUserPresaleInfo = data => ({
  type: SET_USER_PRESALE_DATA,
  data
})

export const setQuizSummaryRedirectUrl = redirectInfoUrl => ({
  type: SET_QUIZ_SUMMARY_REDIRECT_URL,
  redirectInfoUrl
})

export const getPresaleUserData = id => () => {
  // eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    try {
      const {
        data: { result: user }
      } = await provenApi.get(`${campaignCaptureQuizUrl}?id=${id}`)
      resolve(user)
    } catch (error) {
      reject(error)
    }
  })
}

export const saveUserQuiz =
  (data, questionnaireId, doNotClearQuizAnswers = false) =>
  dispatch => {
    // eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
      try {
        const { firstName, email } = data
        dispatch(setUserQuizInfo({ firstName, email }))
        dispatch({ type: CREATE_USER_QUIZ_DATA })
        const { data: dataResponse } = await provenApi.post(userUrl, data)
        dispatch({ type: SUCCESS_USER_QUIZ_DATA, questionnaireId })
        if (!doNotClearQuizAnswers) {
          dispatch(clearQuizAnswers(null, questionnaireId))
        }
        if (dataResponse?.result?.user) {
          dispatch(setMostRecentQuizId(questionnaireId))
          dispatch(setAccountCompleteQuiz(dataResponse?.result?.user))
        }
        resolve(dataResponse?.result)
      } catch (error) {
        dispatch({ type: FAIL_USER_QUIZ_DATA })
        reject(error)
      }
    })
  }

export const saveEmptyUser = data => dispatch => {
  // eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    try {
      const { data: dataResponse } = await provenApi.post(userUrl, data)
      if (dataResponse?.result?.user) {
        dispatch(setAccountCompleteQuiz(dataResponse?.result?.user))
        dispatch(
          setAccountPersonalInfo({
            doubleOptInEmail: dataResponse?.result?.user?.info?.doubleOptInEmail
          })
        )
      }
      resolve(dataResponse?.result)
    } catch (error) {
      dispatch({ type: FAIL_USER_QUIZ_DATA })
      reject(error)
    }
  })
}

export const saveUserPresale = data => dispatch => {
  // eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    try {
      const { firstName, lastName, email } = data
      dispatch(setUserPresaleInfo({ firstName, lastName, email }))
      dispatch({ type: CREATE_USER_PRESALE_DATA })
      const { results } = await provenApi.post(presaleUserUrl, data)
      dispatch({ type: SUCCESS_USER_PRESALE_DATA })
      resolve(results)
    } catch (error) {
      dispatch({ type: FAIL_USER_PRESALE_DATA })
      reject(error)
    }
  })
}

export const patchUserQuiz =
  (answers, questionnaireId, doNotClearQuizAnswers) => async (dispatch, getState) => {
    try {
      const totalAnswers = Object.keys(answers).length
      dispatch(setMostRecentQuizId(questionnaireId))
      // const questionsKey = questionnaireId ? `questions_${questionnaireId}` : 'questions'

      const pathASelector = questionsSelectorGeneric(questionnaireId)
      const store = getState()
      const questionsA = pathASelector(store)
      const userInfo = userInfoSelector(store)
      const account = accountSelector(store)
      const sephoraStoreId = account?.sephoraStoreId
      const sephoraAdvisorName = account?.sephoraAdvisorName

      const questions = questionsA.filter(q => {
        return q?.question?._id !== undefined
      })

      //const questionsfirstLastIds = [questions[0], questions[questions.length-1]].map(q => {return q.question._id;});
      //must answer first & last question & 25% of all the questions
      //const answersIncludesFirstAndLastQuestion = questionsfirstLastIds.every(q => {return answerKeys.indexOf(q) >= 0;});
      //const isQuizComplete = (totalAnswers > (questions.length / 4)) && answersIncludesFirstAndLastQuestion;

      //must answer  25% of all the questions
      const isQuizComplete = totalAnswers > questions.length / 4

      console.log(`quiz.actions - patchUserQuiz - questionnaireId: `, questionnaireId)
      console.log(`quiz.actions - patchUserQuiz - number of answers: `, totalAnswers)
      console.log(`quiz.actions - patchUserQuiz - questions.length: `, questions.length)
      console.log(`quiz.actions - patchUserQuiz - isQuizComplete: `, isQuizComplete)

      // const quizCompleted = quizCompletedSelector(getState());
      // if (!quizCompleted) {
      if (!isQuizComplete) {
        const congratsPageUri = getCongratPageUri(questionnaireId)
        history.push(congratsPageUri)
        throw 'Please, complete the quiz before submit.'
      }
      //dispatch({ type: CREATE_USER_QUIZ_DATA })

      let results = undefined
      if (isQuizComplete) {
        const zipCode = getZipCodeFromAnswers(answers)
        let firstName = userInfo.firstName,
          lastName = userInfo.lastName
        const name = getNameFromAnswers(answers)
        if (name) {
          const nameArray = name.split(' ')
          firstName = nameArray[0].trim()
          if (nameArray.length > 1) {
            lastName = nameArray.slice(1).join(' ').trim()
          }
        }
        const putData = addValuesToObjectOnlyIfDefined(
          {
            firstName,
            lastName,
            answers,
            zipCode,
            questionnaireId
          },
          {
            sephoraStoreId,
            sephoraAdvisorName
          }
        )
        const apiDataResults = await provenApi.put(userQuizUrl, putData)
        results = apiDataResults?.data?.result ? apiDataResults?.data?.result : undefined
        if (results?.user) {
          dispatch(setAccountCompleteQuiz(results?.user))
          dispatch(setAccountPersonalInfo({ firstName: results?.user?.info?.firstName }))
          dispatch(setAccountPersonalInfo({ lastName: results?.user?.info?.lastName }))
          dispatch(setAccountPersonalInfo({ zipCode: results?.user?.info?.zipCode }))
          dispatch(setAccountPersonalInfo({ countryCode: results?.user?.info?.countryCode }))
        }
      }
      dispatch({ type: SUCCESS_USER_QUIZ_DATA, questionnaireId: questionnaireId })
      if (!doNotClearQuizAnswers) {
        dispatch(clearQuizAnswers(null, questionnaireId))
      }
      return results
    } catch (error) {
      dispatch({ type: FAIL_USER_QUIZ_DATA })
      throw error
    }
  }

export const getProductsByQuery = query => {
  if (!query) return Promise.resolve([])

  return new Promise(resolve => {
    provenApi.get(searchProductsUrl(query)).then(({ data }) => {
      const { result } = data
      let options = []
      for (let i = 0; i < result.length; i++) {
        options.push({
          label: result[i].text,
          value: result[i].text
        })
      }
      resolve(options)
    })
  })
}

export const setQuizPendingStatus = data => dispatch => {
  dispatch({
    type: SET_QUIZ_PENDING_STATUS,
    data
  })
}

export const setAutocompleteInputValue = (data, questionnaireId) => dispatch => {
  dispatch({
    type: SET_AUTOCOMPLETE_INPUT_VALUE,
    data,
    questionnaireId
  })
}

export const getFollowUpQuestions = answers => async dispatch => {
  const { data } = await provenApi.post('get-followup-questions', { answers })
  const questionnaire = data?.result?.questionnaire
  dispatch({
    type: SET_FOLLOWUP_QUESTIONS,
    questionnaire
  })
}

export const updateFollowUpAnswers = answers => ({
  type: SET_FOLLOWUP_ANSWERS,
  answers
})

export const updateFollowUpQuestions = answers => async dispatch => {
  try {
    const { data } = await provenApi.put('followup-answers', { answers })
    const newZipCode = data?.zipCode
    // Update user profile with new zipCode
    dispatch(setAccountPersonalInfo({ zipCode: newZipCode }))
    dispatch({
      key: new Date().getTime() + Math.random(),
      type: CREATE_NOTIFICATION,
      strong: 'Success!:',
      text: 'Your profile was updated.'
    })
  } catch (error) {
    dispatch({
      type: CREATE_NOTIFICATION,
      key: new Date().getTime() + Math.random(),
      strong: 'Error:',
      text: 'There was an error updating your profile'
    })
  }
}

const passwordResetRequired = answer => ({
  type: SIGNIN_PASSWORD_FAIL,
  error: 'PASSWORD_RESET_REQUIRED',
  username: answer,
  message: ''
})

export const getEmailVerification = email => {
  // eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await provenApi.get(getValidateEmailUrl, {
        params: { email: email }
      })
      resolve(data)
    } catch (error) {
      reject(error)
    }
  })
}
