import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box, Stack, Typography } from '@mui/material'
import ConcernItem from './concernItem'
import { BUILDER_CDN } from '../../../../../../constants/endpoints'
import { createProvenTheme } from '../../../../../../styles/theme-proven'
import { Image } from 'components/builder/blocks/shared/Image'

const theme = createProvenTheme()
const spacing = theme.spacing

const LEFT_SIDE_CONCERN_BACKGROUND_IMG_URL = `${BUILDER_CDN}9be7b3a343374be9b104de2868ae6f69/821d475bd1a940da95c3cd5cd58a06fa`
const RIGHT_SIDE_CONCERN_BACKGROUND_IMG_URL = `${BUILDER_CDN}9be7b3a343374be9b104de2868ae6f69/f7ffe126b88c4095b35a3388b2df8bb0`
const COMMON_BACKGROUND_IMG_URL = `${BUILDER_CDN}9be7b3a343374be9b104de2868ae6f69/ae0500bad7c441ec821655fe4b48fc8c`

function Concerns({ skin, skinConcernsDescription }) {
  const isLgUp = useMediaQuery(theme => theme.breakpoints.up('lg'))
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'))

  const gridTemplateAreas = !isLgUp
    ? `"title title title title"
                                      "description description description description"
                                       "concerns concerns concerns concerns"`
    : `"title title title title title . . . . . . ."
                                      "description description description description description description . . . . . ."
                                      "concerns concerns concerns concerns concerns concerns concerns concerns concerns concerns concerns concerns"`

  const getConcernBackgroundImgUrl = position => {
    if (!isLgUp) return COMMON_BACKGROUND_IMG_URL
    if (position === 0) {
      //there is not a background image for first ConcernItem on desktop
      return undefined
    }
    return `${
      position % 2 ? LEFT_SIDE_CONCERN_BACKGROUND_IMG_URL : RIGHT_SIDE_CONCERN_BACKGROUND_IMG_URL
    }`
  }

  return (
    <Box position="relative">
      {isLgUp && (
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            height: spacing(143.375),
            width: spacing(104),
            mx: { xs: -2, md: -8, lg: '-150px', xxl: '-10%' },
            boxShadow: 'unset',
            borderRadius: 'unset'
          }}
        >
          <Image
            backgroundPosition="top right"
            image={COMMON_BACKGROUND_IMG_URL}
            aspectRatio={0.7254}
          />
        </Box>
      )}
      <Box
        pt={12}
        sx={{
          display: 'grid',
          gridTemplateColumns: isLgUp ? 'repeat(12, 1fr)' : 'repeat(4, 1fr)',
          gridTemplateRows: 'auto auto auto',
          gridTemplateAreas
        }}
      >
        <Typography zIndex={4} sx={{ gridArea: 'title' }} pb={{ xs: 1, md: 2 }} variant="h2">
          Based on Your
          <br />
          <Typography component="span" variant="inherit" color="primary.main">
            {'Skin Concerns'}
          </Typography>
        </Typography>
        <Typography
          zIndex={4}
          sx={{ gridArea: 'description', pb: { xs: 8, md: 10.5 } }}
          variant="body2"
          color="gray.darkGray"
        >
          {skinConcernsDescription && skinConcernsDescription?.trim()
            ? skinConcernsDescription
            : `Your Personalized Skincare is tailor-made to your skin’s needs. Powerhouse ingredients are carefully chosen to deliver the best results for you based on your unique skin concerns.`}
        </Typography>

        <Stack gridArea="concerns" gap={isMdUp ? 20 : 11}>
          {skin
            .filter(
              c =>
                c.body &&
                c.vital_title &&
                ((c.ingredients && c.ingredients.length) || c.genome_clinical_percent)
            )
            .map((c, index) => (
              <ConcernItem
                key={`concern-${c.vital_title}`}
                {...c}
                title={c.vital_title}
                backgroundImageUrl={getConcernBackgroundImgUrl(index)}
                percentage={c.genome_clinical_percent}
                percentageDescription={c.genome_clinical_text}
                description={c.body}
                ingredients={c.ingredients || []}
                areIngredientsOnTheLeftSide={!!(index % 2)}
                beforeImage={c.genome_img_before}
                afterImage={c.genome_img_after}
                sourceLabel={c.genome_img_source}
              />
            ))}
        </Stack>
      </Box>
    </Box>
  )
}

export default Concerns
